"use strict";

exports.__esModule = true;
exports.noop = exports.defaultProps = exports.defaultOptions = exports.defaultConfig = void 0;
var defaultOptions = exports.defaultOptions = {};
var defaultConfig = exports.defaultConfig = {
  disconnectOnLeave: false
};
var noop = () => {};
exports.noop = noop;
var defaultProps = exports.defaultProps = {
  onEnterViewport: noop,
  onLeaveViewport: noop
};