"use strict";

exports.__esModule = true;
exports.useInViewport = exports.handleViewport = exports.default = exports.customProps = void 0;
var _handleViewport = _interopRequireDefault(require("./lib/handleViewport"));
exports.handleViewport = _handleViewport.default;
var _useInViewport = _interopRequireDefault(require("./lib/useInViewport"));
exports.useInViewport = _useInViewport.default;
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var customProps = exports.customProps = ['inViewport', 'enterCount', 'leaveCount'];
var _default = exports.default = _handleViewport.default;